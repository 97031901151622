<template>
    <div :class="data.Class">
        <div :class="data.TextClass" v-html="data.Value" v-if="!data.MutateValue" class="multiline"></div>
        <div :class="data.TextClass" v-else>
            {{ getString() !== data.MutateValue ? getString() : data.Value }}
        </div>
    </div>
</template>
<script>
export default {
    props: {
        data: Object
    },
    methods: {
        getString () {
            console.log('check get string')
            var data = this.data.MutateValue
            if (!data) return false
            var value = this.stringReader(data)
            return value
        }
    }
}
</script>
<style lang="scss" scoped>
    @import url('https://fonts.googleapis.com/css?family=Poppins:400,500,700,900');
    textarea {
            font-family:  "Poppins", "Montserrat", Helvetica, Arial, sans-serif;
    }
    .multiline {
        white-space: pre-wrap;
    }
</style>
